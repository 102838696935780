<template>
  <section class="mobile-app">
    <div>
      <menubar></menubar>

      <div class="body-part">
        <div class="main-body-content">
          <div v-if="panelHeight > 0" class="row">
            <div v-for="(panel, key) in panels" :key="key" class="col-3">
              <div
                :style="{ 'background-color': panel.color }"
                class="title-content"
              >
                <div class="title-text">
                  <h1>{{ panel.name }}</h1>
                  <h4 style="background-color: #bf9de9">
                    {{ panel.tickets.length }}
                  </h4>
                </div>
                <div class="title-icon"></div>
              </div>
              <div :style="{
              height:`${panelHeight}px`,
              overflowX:'scroll',
              marginBottom: '10px'
              }" class="draggable-container">
              <draggable
                :id="panel.id"
                class="draggable-container"
                group="people"
                :list="panel.tickets"
                @change="log"
                @end="onEnd"
              >
                <div
                  v-for="(ticket, key) in panel.tickets"
                  :key="key"
                  class="body-box"
                  @click.stop="
                    (action_type = 'IS_UPDATE'),
                      (drawer = !drawer),
                      (ticketRecordModal = { ...ticket })
                  "
                >
                  <h5>{{ ticket.service }}</h5>
                  <h1 :title="ticket.title" class="title">
                    {{ ticket.title }}
                  </h1>
                  <p class="description">{{ ticket.description }}</p>
                  <h2 class="urgency">{{ ticket.urgency }}</h2>

                  <div class="box-footer">
                    <div class="footer-icon">
                      <i class="fa fa-comments" aria-hidden="true"></i>
                      <p>0</p>
                    </div>

                    <div class="footer-icon">
                      <i class="fa fa-link" aria-hidden="true"></i>
                      <p>0</p>
                    </div>
                  </div>
                </div>
              </draggable>
              
              </div>
              <div v-if="key + 1 !== panels.length" class="dashed-box">
                <a
                  @click.prevent="
                    (action_type = 'IS_INSERT'),
                      (drawer = true),
                      (ticketRecordModal.panel_id = panel.id)
                  "
                  href=""
                >
                  <h1 @click="clearInput">
                    <span style="color: #858587"> +</span> Add Card
                  </h1>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      class="aside-main-section"
      absolute
      temporary
      right
      :width="action_type === 'IS_INSERT' ? '30%' : '70%'"
      height="100vh"
    >
      <div style="overflow-y: hidden" class="v-navigation-drawer__content">
        <div class="row" style="height: 100%">
          <div v-if="action_type !== 'IS_INSERT'" class="col-7 pr-0">
            <ticketchat :ticket_id="ticketRecordModal.id"></ticketchat>
          </div>
          <div
            :class="{
              'col-12': action_type === 'IS_INSERT',
              'col-5': action_type !== 'IS_INSERT',
              'pl-0':action_type !== 'IS_INSERT',
            }"
          >
            <div class="chatting-info-section">
              <div class="display-chat-header">
                  <div class="chat-message-box">
                    <div class="chat-who-img">
                      
                      <img
                        src="https://image.ibb.co/cVTEsG/eAcademy.png"
                        alt=""
                      />
                    </div>
                    <div class="chat-msg-content">
                      
                    </div>
              </div>
              </div>
              <div class="right-chat-window">
                <div class="chatting-contact-info">
                  <div class="pl-5">
                      
                  <div style="padding:0;margin-left:-5px" ref="textAreaContainer">
                    <textarea
                    @input="controlHeight"
                    ref="resizableContent"
                    class="ticket-input-field title"
                    style="text-indent:0;padding-left:5px;margin-bottom:10px;height:40px;"
                            placeholder="Ticket Title"
                            v-model="ticketRecordModal.title">
                    </textarea>
                    </div>
                    <div class="chat-email-info">
                      <span class="material-icons"> mail_outline </span>
                      <input
                        type="email"
                        style="color: #78a300"
                        placeholder="E-mail"
                        class="ticket-input-field"
                        v-model="ticketRecordModal.email"
                      />
                    </div>
                    <div class="chat-email-info">
                      <span class="material-icons"> phone </span>
                      <input
                        type="number"
                        maxlength="10"
                        placeholder="Contact Number"
                        class="ticket-input-field"
                        v-model="ticketRecordModal.contact_number"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        pattern="[789][0-9]{9}"
                      />
                    </div>
                    <div class="chat-email-info">
                      <span class="material-icons"> person_outline </span>
                      <input
                        type="text"
                        v-on:keypress="alphabets($event)"
                        placeholder="Full Name"
                        class="ticket-input-field"
                        v-model="ticketRecordModal.creator_name"
                      />
                    </div>
                  
                    <div class="chat-email-info mb-3">
                      <span class="material-icons"> sell </span>
                      <div class="border-background-list">
                        <select
                        style="margin-left:0;"
                          v-model="ticketRecordModal.urgency"
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option value="normal">Normal</option>
                          <option value="urgent">Urgent</option>
                          <option value="critical">Critical</option>
                        </select>
                      </div>
                      <div class="border-background-list">
                        <select
                          v-model="ticketRecordModal.service"
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option value="Billing">Billing</option>
                          <option value="Academic">Academic</option>
                          <option value="Examination">Examination</option>
                          <option value="Miscellenous">Miscellenous</option>
                          <option value="ID Card">ID Card</option>
                          <option value="Students">Students</option>
                          <option value="Members">Members</option>
                          <option value="Transportation">Transportation</option>
                          <option value="Notice">Notice</option>
                          <option value="SMS">SMS</option>
                          <option value="Library">Library</option>
                        </select>
                      </div>
                    </div>
                    <div class="chat-email-info">
                      <span class="material-icons"> home </span>
                      <h1
                        style="color: #fff; background-color: rgb(129, 45, 231);margin-left:0;"
                        class="border-background"
                      >
                        {{ panel_status }}
                      </h1>
                    </div>
                    <div class="chat-email-info">
                      <textarea
                        id="fname"
                        name="fname"
                        class="chat-box description"
                        placeholder="Start typing to add ticket's description..."
                        v-model="ticketRecordModal.description"
                      >
                      </textarea>
                    </div>
                    <div class="chat-button">
                      <v-btn
                        v-if="action_type == 'IS_INSERT'"
                        class="chat-message-button"
                        @click="saveTicket()"
                        style="background-color: #78a300 !important"
                        depressed
                        color="primary"
                        :disabled="!ticketRecordModal.title"
                      >
                        Create Ticket
                      </v-btn>
                      <v-btn
                        v-else
                        class="chat-message-button"
                        @click="updateTicket()"
                        style="background-color: #78a300 !important"
                        depressed
                        color="primary"
                        :disabled="!ticketRecordModal.title"
                      >
                        Update Ticket
                      </v-btn>

                      <v-dialog v-model="dialog" persistent max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="background-color: #f7050d !important"
                          >
                            Delete Ticket
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Are you sure you want to delete this ticket?
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialog = false"
                            >
                              No
                            </v-btn>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="deleteTicket()"
                            >
                              Yes
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                </div>
                  <ticketlog :ticketId="ticketRecordModal.id"></ticketlog>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </section>
</template>

<script>
import menubar from "./menubar.vue";
import ticketlog from "./ticketLog.vue";
import ticketchat from "./ticketChat.vue";
import draggable from "vuedraggable";
import { RestService, SERVICELIST } from "@/library/RestService";
const $restService = RestService(SERVICELIST.SUPPORT_TICKET);

export default {
	components: {
		draggable,
		menubar,
		ticketlog,
		ticketchat,
	},
	data() {
		return {
			action_type: "IS_INSERT", //IS_INSERT, IS_UPDATE
			dialog: false,
			drawer: false,
			panels: [],
			showDeleteTicketModal: false,
			tickets: [],
			urgencies: ["normal", "urgent", "critical"],
			panelHeight: 0,
			services: [
				"Billing",
				"Academic",
				"Examination",
				"Miscellenous",
				"ID Card",
				"Students",
				"Members",
				"Transportation",
				"Notice",
				"SMS",
				"Library",
			],
			ticketRecordModal: {
				panel_id: "",
				id: "",
				contact_number: "",
				description: "",
				email: "",
				creator_name: "",
				service: "",
				title: "",
				urgency: "",
			},
		};
	},
	mounted() {
		this.get();
		this.panelHeight=window.innerHeight-48-150-(30+15)-100
		
	},
	computed: {
		panel_status() {
			let p_status = "";
			switch (this.ticketRecordModal.panel_id) {
				case 1:
					p_status = "Next Up";
					break;
				case 2:
					p_status = "In Progress";
					break;
				case 3:
					p_status = "Review";
					break;
				case 4:
					p_status = "Completed";
					break;
			}
			return p_status;
		},
	},
	methods: {
		controlHeight(){
			const $refs = this.$refs['resizableContent'];
			$refs.style.height = "auto";
			$refs.style.height = ($refs.scrollHeight)+"px";
		},
		alphabets(e) {
			let char = String.fromCharCode(e.keyCode); // Get the character
			if (/^[A-Za-z]+$/.test(char)) return true; // Match with regex
			else e.preventDefault(); // If not match, don't add to input text
		},
		log: function (evt) {
			if (evt.added && evt.added.element) {
				this.updateTicketRecord = evt.added.element;
			}
		},
		showDeleteTicketModals() {
			this.showDeleteTicketModal = true;
		},
		get() {
			$restService.get("/api/panels").then(({ data }) => {
				const { rows } = data;
				this.panels = rows;
			});
		},
		saveTicket() {
			$restService
				.post("/api/ticket", this.ticketRecordModal)
				.then(({ data }) => {
					this.get();
					this.drawer = false;
					this.saveLog({
						ticket_id: data.data.id,
						logged: " created New Ticket",
						action_type: "TICKET_CREATE",
						payload: data.data,
					});
				});
		},
		deleteTicket() {
			$restService
				.delete("/api/ticket/" + this.ticketRecordModal.id)
				.then(({ data }) => {
					this.get();
					this.drawer = false;
				});
		},
		updateTicket() {
			$restService
				.put("/api/ticket/" + this.ticketRecordModal.id, this.ticketRecordModal)
				.then(({ data }) => {
					this.get();
					this.drawer = false;
					this.saveLog({
						ticket_id: this.ticketRecordModal.id,
						logged: " updated the Ticket",
						action_type: "TICKET_UPDATE",
						payload: data.data,
					});
				});
		},

		onEnd: function (evt) {
			const requestPayload = {
				...this.updateTicketRecord,
				panel_id: parseInt(evt.to.id),
			};

			const newPanelId = requestPayload.panel_id;
			const oldPanelId = this.updateTicketRecord.panel_id;
			$restService
				.put(`/api/ticket/${requestPayload.id}`, requestPayload)
				.then(() => {
					this.get();
					const newPanelName = this.panels.find(
						(item) => item.id === newPanelId
					);
					const oldPanelName = this.panels.find(
						(item) => item.id === oldPanelId
					);

					this.saveLog({
						ticket_id: requestPayload.id,
						logged:
              " moved Ticket to  " +
              newPanelName.name +
              " from " +
              oldPanelName.name,
						action_type: "PANEL_UPDATE",
						payload: requestPayload,
					});
				});
		},
		saveLog({ ticket_id, logged, payload, action_type }) {
			$restService.post(`/api/ticket-log`, {
				ticket_id: ticket_id,
				logged: logged,
				payload: payload,
				action_type: action_type,
			});
		},
		clearInput() {
			(this.ticketRecordModal.contact_number = ""),
			(this.ticketRecordModal.description = ""),
			(this.ticketRecordModal.email = ""),
			(this.ticketRecordModal.creator_name = ""),
			(this.ticketRecordModal.service = "Billing"),
			(this.ticketRecordModal.title = ""),
			(this.ticketRecordModal.urgency = "normal");
			// (this.ticketRecordModal.end_date = "");
			this.ticketRecordModal.id = "";
		},
		// urgency_color() {
		// 	let u_color = "";
		// 	switch (this.ticketRecordModal.urgency) {
		// 		case "normal":
		// 			u_color = "green";
		// 			break;
		// 		case "urgent":
		// 			u_color = "red";
		// 			break;
		// 		case "critical":
		// 			u_color = "orange";
		// 			break;
		// 	}
		// 	return u_color;
		// },
	},
	watch: {
		'ticketRecordModal.title': function(e){
			
		},
		drawer: function (value) {
			if (!value) {
				//close
				this.clearInput();
				document.body.style.overflow = "auto";
			} else {
				// open
				document.body.style.overflow = "hidden";
			}
		},
	},
};
</script>
<style lang="scss">
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.draggable-container {
  min-height: 15px;
}
.urgency {
  text-transform: capitalize;
}
.border-background-list {
  background-color: #ffffff !important;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 50px !important;
}
</style>
